/* Setting */
html {	letter-spacing: 0.05rem !important;}
h1 { 	font-size: 1.8rem !important;	 margin: 0;	@include breakpoint(small down) {	font-size: 1.4rem !important;}}
h2, h3, dt {	margin-bottom: 1rem !important;}
h2 {	@include breakpoint(small down) {	font-size: 1.9rem;}}
label {	font-size: 1rem;}
i {	padding-right: 0.5rem;}
dt {	font-size: 1.9375rem;	font-weight: normal !important;
	@include breakpoint(small down) {	font-size: 1.5rem;}
}
.none-display {	display: none;}


/* Off-canvas */
.off-canvas{
	background: $primary-color;
	&.is-transition-overlap {	z-index: 1000;}
	> .close-button {	color: $white;	background: $secondary-color; margin: 0 -0.5rem;	padding: 0 0.3rem;
		&:hover {	color: $medium-gray;	background: $quinary-color;}
	}
	ul {	margin-top: 3rem;
		li {
			&.active a {	background: $quinary-color !important;}
			> a {	color: $white;	-webkit-tap-highlight-color: rgba(0,0,0,0) !important;	-webkit-box-shadow: none !important;	box-shadow: none !important;	outline: none !important;
				&.vmenu {	position: relative;	z-index: 1;}
				&.vmenu:after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 100%;
					transform: scaleX(0);
					transform-origin: 0 100%;
					background: $secondary-color;
					z-index: -1;
					transition: transform 0.3s;
				}
				&.vmenu:hover::after {	transform: scaleX(1);}
				&.active {	-webkit-tap-highlight-color: rgba(0,0,0,0) !important;	-webkit-box-shadow: none !important;	box-shadow: none !important;	outline: none !important;}
			}
		}
	}
}


/* Header */
header, footer {
	a {	color: $white;
		&:hover {	color: $medium-gray;}
	}
}
// .sticky-container {	@include breakpoint(small down) {	height: 6rem;}}
#headnav {z-index: 100;}
#head {
	color: $white;
	padding: 1.5rem;
	font-size: 2rem;
	font-weight: bold;
	background: $primary-color;
	@include breakpoint(small down) {	font-size: 1.2rem;	padding: 1rem 0;
		.text-right {	text-align: left !important; padding-top: 0.3rem;}
	}
	&.row {	max-width: 100% !important;}
	#logo {	font-family: "logo";	letter-spacing: 0.3rem;}
	.icon-logomark {	font-size: 2rem;	@include breakpoint(small down) {	font-size: 1.6rem;}}
}


/* Header > Global Navigation */
nav {
	display: flex;
	background: $secondary-color;
	.title-bar {	padding: 0.6rem 2rem;	@include breakpoint(small down) {	padding: 0.6rem 1rem;}}
	.title-bar-title {	font-weight: normal;	font-size: 1.5rem;}
	.top-bar, .top-bar-left, .top-bar-left li, .title-bar, ul.menu {	background: $secondary-color;}
	.top-bar-left li a {	color: $white;}
	button {	margin: 0 1rem 0 0.5rem !important;}
	#menu-1 li a:hover {background: $primary-color;}
	// [id^=menu-] li a:hover {background: $primary-color;}
}


/*Header >  Orbit */
.orbit {
	button[class^=orbit-], button[class^=orbit-]:hover, button[class^=orbit-]:focus {	-webkit-border-radius:45px !important;	-moz-border-radius:45px !important;	border-radius:45px !important;}
	.orbit-caption {	background-color: rgba(10, 10, 10, 0.35);	@include breakpoint(small down) {	display: none;}	}
	#partscleann {	margin-top: -2.5rem;	@include breakpoint(small down) {	display: none;}
		button {	background-color: $secondary-color;
			&.is-active {	background-color: $quaternary-color;}
		};
	}
}


/* Body */
.columns {	@include breakpoint(small down) {	padding: 0 1.5rem;}}
section {	margin-bottom: 3.5rem !important;
	&:first-child {	padding-top: 4rem;}
	&:last-child {	padding-bottom: 4rem;}
	&:nth-child(2), &:nth-child(3) {	margin-top: -4rem;	padding-top: 4rem;}
	@include breakpoint(small down) {
		&:first-child {	margin-top: -2rem;	padding-top: 5rem;}
		&:nth-child(2), &:nth-child(3) {	margin-top: -5rem;	padding-top: 5rem;}
	}
	.row .column .card {	background: $tertiary-color;	color: $white;
		> .card-section dl dd:nth-of-type(2), > .card-section dl dd:nth-of-type(3) {	margin-bottom: 1rem;}
		> .card-section dl dd:nth-of-type(4) {	font-size: 0.8rem;}
	}
	.row .column:last-child dl dd {	padding-left: 1rem;
		&.indent {	padding-left: 0 !important;}
		&:nth-of-type(2) {	margin-bottom: 0 !important;}
		&:nth-of-type(3) {	margin-bottom: 1rem;}
		&:nth-of-type(4) {	font-size: 1rem !important;}
	}
	> .vhc {	margin-top: 2.5rem !important;}
	&#area p {	margin: 0 1rem 1rem;}
	&#notes ul {	line-height: 1.8;}
}
/* Body > table */
$table-hover: smart-scale($table-background, $table-color-scale);
table {
	word-break: keep-all;
	display: table !important;
	border-collapse: separate !important;
	border-spacing: 2px !important;
	border-color: transparent !important;
	&:hover tbody tr:hover td, td:hover {	background: $table-hover;}
	&:hover tbody tr:hover th, td:hover {	background: $table-hover;}
	caption {	display: none !important;}
	thead {	background: smart-scale($table-background, $table-color-scale * 2);
		tr th {
			&.lease, &.condo, &.house {	width: 25%;}
			&.wcontent {	width: 50%;}
		}
	}
	tbody th {	text-align: left;	font-weight: normal;}
	td {	background-color: #f5f5f5}
}
.table-scroll {	@include breakpoint(small down) {	margin-bottom: 2rem;}
	table {	width: 100% !important;}
	&::-webkit-scrollbar{	height: 10px;}
	&::-webkit-scrollbar-track{	background: $light-gray;}
	&::-webkit-scrollbar-thumb{	background: $primary-color;}
}
#company table {	word-break: break-all;
	@include breakpoint(small down) {	font-size: 0.95rem;
		tbody th:first-child {width: 30%;}
	}
}


/* Footer */
footer {	background: $tertiary-color;	color: $white;
	> .ftrow {	min-width: 100% !important;	background: $senary-color;	padding-left: 2.5rem;
		@include breakpoint(small down) {	padding-left: 0;
			.center	{	text-align: center;	justify-content: center;}
			div:first-child {	padding: 2rem 0 1rem;}
			div:last-child ul.align-right {	justify-content: center;	padding-bottom: 1rem;}
		}
	}
	i {	font-size: 2.5rem;}
	p {	margin: 0 !important;}
	button {
		background-color: $primary-color;
		-webkit-transition: all 0.2s ease-in-out !important;
		transition: all 0.2s ease-in-out !important;
		@include breakpoint(small down) {	margin-left: 1rem !important;}
	}
	button.button:hover {	padding: 0.85em 3em;	background-color: $primary-color !important;}
	button.button:active {	background-color: $quinary-color !important;}
	input:focus, textarea:focus {	color: $medium-gray;}
}
